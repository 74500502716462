import React, { useEffect, useState, useMemo } from "react";
import { Amplify, API } from "aws-amplify";
import AWSConfig from "./aws-exports";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  FormControlLabel,
  TextField,
  Button,
  Dialog,
  Switch,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Alert,
  AlertTitle,
  Typography,
} from "@mui/material";
import "./App.css";

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const inactiveProjects = projects?.filter(project => project.active === false);
  const [loading, setLoading] = useState(false);
  const [showInactiveProjects, setShowInactiveProjects] = useState(false);
  const [search, setSearch] = useState("");
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showNewProjectDialog, setShowNewProjectDialog] = useState(false);
  const [recordToEdit, setRecordToEdit] = useState({});
  const [sortKey, setSortKey] = useState("project");
  const [reverse, setReverse] = useState(false);

  const filteredProjects = useMemo(() => {
    const projectsToFilter = showInactiveProjects ? inactiveProjects : projects
    return projectsToFilter.filter(
      (item) =>
        item.project.toLowerCase().includes(search.toLowerCase()) &&
        (item.active === true || showInactiveProjects)
    );
  }, [projects, inactiveProjects, search, showInactiveProjects]);

  // Sorting function using useMemo to optimize performance
  const sortedProjects = useMemo(() => {
    const sorted = [...filteredProjects].sort((a, b) => {
      if (sortKey === "project" || sortKey === "technical_contact") {
        return a[sortKey].localeCompare(b[sortKey]);
      }
      if (sortKey === "active" || sortKey === "reports") {
        return a[sortKey] === b[sortKey] ? 0 : a[sortKey] ? -1 : 1;
      }
      return 0;
    });

    return reverse ? sorted.reverse() : sorted;
  }, [filteredProjects, sortKey, reverse]);

  const handleSort = (key) => {
    if (sortKey === key) {
      setReverse(!reverse);
    } else {
      setSortKey(key);
      setReverse(false);
    }
  };

  const getSortIndicator = (key) => {
    if (sortKey === key) {
      return reverse ? "▼" : "▲"; // Up or down arrow
    }
    return "⇅"; // Default sorting indicator
  };

  const fetchData = async () => {
    setLoading(true)
    API.get("projects", "projects", {})
      .then((response) => {
        const data = response.projects;
        data.forEach((item, i) => {
          item.id = i + 1;
        });
        setProjects(data);
        setLoading(false)
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleRowClick = (item) => {
    setRecordToEdit(item);
    setShowEditDialog(true);
  };

  const handleNewProjectClick = () => {
    setRecordToEdit({ active: true, reports: false });
    setShowNewProjectDialog(true);
  };

  const handleEditDialogSave = () => {
    setShowEditDialog(false);
    API.put("projects", "projects", { body: recordToEdit })
      .then(() => {
        fetchData();
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleEditDialogCancel = () => {
    setShowEditDialog(false);
    setRecordToEdit({});
  };

  const handleNewProjectDialogSave = () => {
    setShowNewProjectDialog(false);
    setSearch(recordToEdit.project);
    API.post("projects", "projects", { body: recordToEdit })
      .then(() => {
        fetchData();
      })
      .catch((error) => {
        console.log(error.toString());
      });
  };

  const handleNewProjectDialogCancel = () => {
    setShowNewProjectDialog(false);
    setRecordToEdit({});
  };

  const handleProjectUpdate = (event) => {
    setRecordToEdit((prevState) => ({
      ...prevState,
      [event.target.id]: event.target.value,
    }));
  };

  const handleToggleUpdate = (event) => {
    setRecordToEdit((prevState) => ({
      ...prevState,
      [event.target.id]: !prevState[event.target.id],
    }));
  };

  const handleNewProjectUpdate = (event) => {
    console.log(JSON.stringify(recordToEdit));
    setRecordToEdit((prevState) => ({
      ...prevState,
      [event.target.id]: event.target.value,
    }));
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const toggleShowInactiveProjects = () => {
    setShowInactiveProjects(!showInactiveProjects);
  };

  useEffect(() => {
    Amplify.configure(AWSConfig);
    fetchData();
  }, []);

  if (!projects || loading) {
    return (
      <div className="px-5 py-5 container d-flex justify-content-center align-items-center text-primary">
        <div
          className="spinner-border text-primary"
          role="status"
          style={{ width: "3rem", height: "3rem" }}
        ></div>
        <p className="ms-2 mb-0 fw-normal">Loading...</p>
      </div>
    );
  } else if (projects.length === 0) {
    return (
      <Box mt={4} mx="auto" maxWidth={600}>
        <Alert severity="error" variant="outlined">
          <AlertTitle>Error</AlertTitle>
          <Typography variant="body1" fontWeight={500}>
            No projects
          </Typography>
          <Typography variant="body2" color="text.secondary">
            You are not involved in any projects.
          </Typography>
        </Alert>
      </Box>
    );
  }

  return (
    <>
      <div>
        {/* Top Navigation Bar (Replaces Bootstrap Table) */}
        <TableContainer
          component={Paper}
          sx={{
            p: 2,
            mb: 2,
          }}
        >
          <Table>
            <TableBody>
              <TableRow
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" }, // Column layout on small screens, row layout on medium+
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 2, // Adds spacing between elements
                }}
              >
                {/* Search Input */}
                <TableCell
                  sx={{
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    width: { xs: "100%", md: "33%" }, // Full width on mobile, 33% on desktop
                    borderBottom: "none"
                  }}
                >
                  <label
                    htmlFor="search"
                    style={{
                      fontWeight: "bold",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Search by Project:
                  </label>
                  <TextField
                    id="search"
                    variant="outlined"
                    size="medium"
                    fullWidth
                    onChange={handleSearch}
                    sx={{
                      borderRadius: "8px",
                      fontSize: "1rem",
                      padding: "10px",
                    }}
                  />
                </TableCell>

                {/* New Project Button */}
                <TableCell
                  sx={{
                    flex: 1,
                    textAlign: "center",
                    width: { xs: "100%", md: "33%" }, // Full width on small screens, 33% on large screens
                    borderBottom: "none"
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={handleNewProjectClick}
                    sx={{
                      backgroundColor: "#005EB8",
                      color: "#ffffff",
                      fontSize: "1rem",
                      padding: "12px 24px",
                      "&:hover": { backgroundColor: "#004a93" },
                      width: { xs: "100%", md: "auto" }, // Full-width button on mobile, auto width on desktop
                    }}
                  >
                    New Project
                  </Button>
                </TableCell>

                {/* Show Inactive Projects Toggle */}
                <TableCell
                  sx={{
                    flex: 1,
                    textAlign: "right",
                    display: "flex",
                    justifyContent: "flex-end",
                    width: { xs: "100%", md: "33%" }, // Full width on mobile, 33% on desktop
                    borderBottom: "none"
                  }}
                >
                  <FormControlLabel
                    label="Show inactive projects"
                    sx={{
                      fontSize: "1rem",
                      whiteSpace: "nowrap",
                    }}
                    control={
                      <Switch
                        onChange={toggleShowInactiveProjects}
                        checked={showInactiveProjects}
                      />
                    }
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() => handleSort("project")}
                >
                  Project {getSortIndicator("project")}
                </TableCell>
                <TableCell>SpeedType</TableCell>
                <TableCell
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() => handleSort("technical_contact")}
                >
                  Technical Contact {getSortIndicator("technical_contact")}
                </TableCell>
                <TableCell
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() => handleSort("active")}
                >
                  Active {getSortIndicator("active")}
                </TableCell>
                <TableCell
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() => handleSort("reports")}
                >
                  Send Reports {getSortIndicator("reports")}
                </TableCell>
              </TableRow>
            </TableHead>

            {/* Table Body */}
            <TableBody>
              {sortedProjects.map((item) => (
                <TableRow
                  key={item.id}
                  onClick={() => handleRowClick(item)}
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  <TableCell>{item.project}</TableCell>
                  <TableCell>{item.speedtype}</TableCell>
                  <TableCell>{item.technical_contact}</TableCell>
                  <TableCell>{item.active ? "true" : "false"}</TableCell>
                  <TableCell>{item.reports ? "true" : "false"}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Dialog
        className="dialog"
        open={showEditDialog}
        onClose={handleEditDialogCancel}
      >
        <DialogTitle>Edit Project: {recordToEdit.project}</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <TextField
            margin="dense"
            id="project"
            label="Project"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={recordToEdit.project}
            disabled={true}
          />
          <TextField
            onBlur={handleProjectUpdate}
            margin="dense"
            id="speedtype"
            label="Speedtype"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={recordToEdit.speedtype}
          />
          <TextField
            onBlur={handleProjectUpdate}
            margin="dense"
            id="technical_contact"
            label="Technical Support Contact"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={recordToEdit.technical_contact}
          />

          <FormControlLabel
            label="Active"
            control={
              <Switch
                id="active"
                onChange={handleToggleUpdate}
                checked={recordToEdit.active}
              />
            }
          />

          <FormControlLabel
            label="Send Monthly Reports"
            control={
              <Switch
                id="reports"
                onChange={handleToggleUpdate}
                checked={recordToEdit.reports}
                disabled={!recordToEdit.active}
              />
            }
          />

          <FormControlLabel
            label="Exclude From Shared"
            control={
              <Switch
                id="exclude_shared_admin"
                onChange={handleToggleUpdate}
                checked={recordToEdit.exclude_shared_admin}
                disabled={!recordToEdit.active}
              />
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditDialogSave}>Save</Button>
          <Button onClick={handleEditDialogCancel}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showNewProjectDialog}
        onClose={handleNewProjectDialogCancel}
      >
        <DialogTitle>New Project: {recordToEdit.project}</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <TextField
            onBlur={handleNewProjectUpdate}
            autoFocus
            margin="dense"
            id="project"
            label="Project"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={recordToEdit.project}
          />
          <TextField
            onBlur={handleNewProjectUpdate}
            autoFocus
            margin="dense"
            id="speedtype"
            label="Speedtype"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={recordToEdit.speedtype}
          />
          <TextField
            onBlur={handleNewProjectUpdate}
            autoFocus
            margin="dense"
            id="technical_contact"
            label="Technical Contact"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={recordToEdit.techical_contact}
          />
          <FormControlLabel
            label="Active"
            control={
              <Switch
                id="active"
                onChange={handleToggleUpdate}
                checked={recordToEdit.active}
              />
            }
          />

          <FormControlLabel
            label="Send Monthly Reports"
            control={
              <Switch
                id="reports"
                onChange={handleToggleUpdate}
                checked={recordToEdit.reports}
              />
            }
          />

          <FormControlLabel
            label="Exclude From Shared"
            control={
              <Switch
                id="exclude_shared_admin"
                onChange={handleToggleUpdate}
                checked={recordToEdit.exclude_shared_admin}
              />
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNewProjectDialogSave}>Save</Button>
          <Button onClick={handleNewProjectDialogCancel}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Projects;
