import React, { useEffect, useState, useMemo } from "react";
import { Amplify, API } from "aws-amplify";
import AWSConfig from "./aws-exports";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  FormControlLabel,
  TextField,
  Button,
  Dialog,
  Switch,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  AlertTitle,
  Box,
  Typography,
} from "@mui/material";
import "./App.css";

const Reallocations = () => {
  const [reallocations, setReallocations] = useState([]);
  const inactiveReallocations = reallocations.filter(
    (realloc) => realloc.active === false
  );
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [showInactiveReallocations, setShowInactiveReallocations] =
    useState(false);
  const [search, setSearch] = useState("");
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showNewReallocationDialog, setShowNewReallocationDialog] =
    useState(false);
  const [recordToEdit, setRecordToEdit] = useState({});
  const [targetAttributeError, setTargetAttributeError] = useState(null);
  const [sortKey, setSortKey] = useState("reallocation");
  const [reverse, setReverse] = useState(false);
  const [error, setError] = useState({});

  const filteredReallocations = useMemo(() => {
    const reallocationsToFilter = showInactiveReallocations
      ? inactiveReallocations
      : reallocations;
    return reallocationsToFilter.filter(
      (item) =>
        item.name.toLowerCase().includes(search.toLowerCase()) &&
        (item.active === true || showInactiveReallocations)
    );
  }, [reallocations, inactiveReallocations, search, showInactiveReallocations]);

  const sortedReallocations = useMemo(() => {
    const sorted = [...filteredReallocations].sort((a, b) => {
      if (sortKey === "NAME") {
        return a.name.localeCompare(b.name);
      }
      if (sortKey === "PRIORITY") {
        return a.priority - b.priority;
      }
      if (sortKey === "ACTIVE") {
        return a.active === b.active ? 0 : a.active ? -1 : 1;
      }
      return 0;
    });

    return reverse ? sorted.reverse() : sorted;
  }, [filteredReallocations, sortKey, reverse]);

  const handleSort = (key) => {
    if (sortKey === key) {
      setReverse(!reverse);
    } else {
      setSortKey(key);
      setReverse(false);
    }
  };

  const getSortIndicator = (key) => {
    if (sortKey === key) {
      return reverse ? "▼" : "▲"; // Up or down arrow
    }
    return "⇅"; // Default sorting indicator
  };

  const fetchData = async () => {
    setLoading(true)
    API.get("reallocations", "reallocations", {})
      .then((response) => {
        const data = response.reallocations;
        data.forEach((item, i) => {
          item.id = i + 1;
        });
        setReallocations(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        if (error.status === 403) {
          setError(error.response.data);
        } else {
          setError({
            error: "Error",
            message: error.message,
          });
        }
      });
  };

  const handleRowClick = (item) => {
    setRecordToEdit(item);
    setShowEditDialog(true);
  };

  const handleNewReallocationClick = () => {
    setRecordToEdit({ active: true, type: "reallocation" });
    setShowNewReallocationDialog(true);
  };

  const handleEditDialogSave = () => {
    setShowEditDialog(false);

    API.put("reallocations", "reallocations", { body: recordToEdit })
      .then((response) => {
        fetchData();
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleEditDialogCancel = () => {
    setShowEditDialog(false);
    setRecordToEdit({});
  };

  const handleNewReallocationDialogSave = () => {
    console.log("Creating new reallocation: " + JSON.stringify(recordToEdit));
    setSearch(recordToEdit.name);

    if (!recordToEdit.attribute_to_update) {
      setTargetAttributeError("Please select a target attribute.");
      return;
    }

    setShowNewReallocationDialog(false);

    API.post("reallocations", "reallocations", { body: recordToEdit })
      .then((response) => {
        fetchData();
      })
      .catch((error) => {
        console.log(error.toString());
      });
  };

  const handleNewReallocationDialogCancel = () => {
    setShowNewReallocationDialog(false);
    setTargetAttributeError(null)
    setRecordToEdit({});
  };

  const handleReallocationUpdate = (event) => {
    let target = event.target.id;
    let val = event.target.value;

    if (event.target.type === "number") {
      val = parseInt(val, 10);
    }

    setRecordToEdit((prevState) => ({
      ...prevState,
      [target]: val,
    }));
  };

  const handleToggleUpdate = (event) => {
    setRecordToEdit((prevState) => ({
      ...prevState,
      [event.target.id]: !prevState[event.target.id],
    }));
  };

  const handleNewReallocationUpdate = (event) => {
    console.log(event.target);
    let target = event.target.id || event.target.name;
    let val = event.target.value;
    if (event.target.type === "number") {
      val = parseInt(val, 10);
    }
    setRecordToEdit((prevState) => ({
      ...prevState,
      [target]: val,
    }));
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const toggleShowInactiveReallocations = () => {
    setShowInactiveReallocations(!showInactiveReallocations);
  };

  const calcNewReallocationPriority = () => {
    if (!reallocations || reallocations.length === 0) {
      return 10;
    }

    const highestPriority = reallocations?.reduce((prev, curr) => {
      return Number(curr.priority) > Number(prev.priority) ? curr : prev;
    });
    return Number(highestPriority.priority) + 10;
  };

  useEffect(() => {
    Amplify.configure(AWSConfig);
    fetchData();
  }, []);

  if (error.error && reallocations.length === 0) {
    return (
      <Box mt={4} mx="auto" maxWidth={600}>
        <Alert severity="error" variant="outlined">
          <AlertTitle>Error</AlertTitle>
          <Typography variant="body1" fontWeight={500}>
            {error.error}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {error.message}
          </Typography>
        </Alert>
      </Box>
    );
  } else if (!reallocations || loading) {
    return (
      <div className="px-5 py-5 container d-flex justify-content-center align-items-center text-primary">
        <div
          className="spinner-border text-primary"
          role="status"
          style={{ width: "3rem", height: "3rem" }}
        ></div>
        <p className="ms-2 mb-0 fw-normal">Loading...</p>
      </div>
    );
  }

  return (
    <>
      <div>
        <TableContainer
          component={Paper}
          sx={{
            p: 2,
            mb: 2,
          }}
        >
          <Table>
            <TableBody>
              <TableRow
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" }, // Column layout on small screens, row layout on medium+
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 2, // Adds spacing between elements
                }}
              >
                {/* Search Input */}
                <TableCell
                  sx={{
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    width: { xs: "100%", md: "33%" }, // Full width on mobile, 33% on desktop
                    borderBottom: "none",
                  }}
                >
                  <label
                    htmlFor="search"
                    style={{
                      fontWeight: "bold",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Search by Reallocation:
                  </label>
                  <TextField
                    id="search"
                    variant="outlined"
                    size="medium"
                    fullWidth
                    onChange={handleSearch}
                    sx={{
                      borderRadius: "8px",
                      fontSize: "1rem",
                      padding: "10px",
                    }}
                  />
                </TableCell>

                {/* New Project Button */}
                <TableCell
                  sx={{
                    flex: 1,
                    textAlign: "center",
                    width: { xs: "100%", md: "33%" }, // Full width on small screens, 33% on large screens
                    borderBottom: "none",
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={handleNewReallocationClick}
                    sx={{
                      backgroundColor: "#005EB8",
                      color: "#ffffff",
                      fontSize: "1rem",
                      padding: "12px 24px",
                      "&:hover": { backgroundColor: "#004a93" },
                      width: { xs: "100%", md: "auto" }, // Full-width button on mobile, auto width on desktop
                    }}
                  >
                    New Reallocation
                  </Button>
                </TableCell>

                {/* Show Inactive Projects Toggle */}
                <TableCell
                  sx={{
                    flex: 1,
                    textAlign: "right",
                    display: "flex",
                    justifyContent: "flex-end",
                    width: { xs: "100%", md: "33%" }, // Full width on mobile, 33% on desktop
                    borderBottom: "none",
                  }}
                >
                  <FormControlLabel
                    label="Show inactive reallocations"
                    sx={{
                      fontSize: "1rem",
                      whiteSpace: "nowrap",
                    }}
                    control={
                      <Switch
                        onChange={toggleShowInactiveReallocations}
                        checked={showInactiveReallocations}
                      />
                    }
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleSort("TYPE")}
                >
                  Type {getSortIndicator("TYPE")}
                </TableCell>
                <TableCell
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleSort("PRIORITY")}
                >
                  Priority {getSortIndicator("PRIORITY")}
                </TableCell>
                <TableCell
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleSort("NAME")}
                >
                  Name {getSortIndicator("NAME")}
                </TableCell>
                <TableCell>Query</TableCell>
                <TableCell>Target Attribute</TableCell>
                <TableCell>Target Value</TableCell>
                <TableCell
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleSort("ACTIVE")}
                >
                  Active {getSortIndicator("ACTIVE")}
                </TableCell>
              </TableRow>
            </TableHead>

            {/* Table Body */}
            <TableBody>
              {sortedReallocations.map((item) => (
                <TableRow
                  key={item.id}
                  onClick={() => handleRowClick(item)}
                  sx={{ cursor: "pointer" }}
                >
                  <TableCell>{item.type}</TableCell>
                  <TableCell>{item.priority}</TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>
                    <code
                      style={{ color: "#005EB8" }}
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent row click when clicking on code
                        handleRowClick(item);
                      }}
                    >
                      {item.query.length > 30
                        ? `${item.query.substring(0, 30)}...`
                        : item.query}
                    </code>
                  </TableCell>
                  <TableCell>{item.attribute_to_update}</TableCell>
                  <TableCell>{item.value_to_assign}</TableCell>
                  <TableCell>{item.active ? "true" : "false"}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Dialog
        className="dialog"
        open={showEditDialog}
        onClose={handleEditDialogCancel}
      >
        <DialogTitle>Edit Reallocation: {recordToEdit.name}</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <TextField
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={recordToEdit.name}
          />
          <TextField
            onChange={handleReallocationUpdate}
            margin="dense"
            id="priority"
            label="Priority"
            type="number"
            variant="standard"
            defaultValue={recordToEdit.priority}
          />
          <TextField
            onBlur={handleReallocationUpdate}
            sx={{
              textarea: {
                fontFamily: "monospace",
              },
            }}
            multiline
            minRows={4}
            margin="dense"
            id="query"
            label="Query"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={recordToEdit.query}
          />
          <FormControl margin="dense" fullWidth variant="standard">
            <InputLabel id="attribute-to-update-label">
              Target Attribute
            </InputLabel>
            <Select
              labelId="attribute-to-update-label"
              id="attribute_to_update"
              label="Target Attribute"
              defaultValue="project"
              onChange={handleReallocationUpdate}
            >
              <MenuItem value="project" id="attribute_to_update">
                project
              </MenuItem>
            </Select>
          </FormControl>
          <TextField
            onBlur={handleReallocationUpdate}
            margin="dense"
            id="value_to_assign"
            label="Target Value"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={recordToEdit.value_to_assign}
          />
          <FormControlLabel
            label="Active"
            control={
              <Switch
                margin="dense"
                id="active"
                onChange={handleToggleUpdate}
                checked={recordToEdit.active}
              />
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditDialogSave}>Save</Button>
          <Button onClick={handleEditDialogCancel}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showNewReallocationDialog}
        onClose={handleNewReallocationDialogCancel}
      >
        <DialogTitle>New Reallocation: {recordToEdit.name}</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="type"
            label="Type"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={recordToEdit.type}
            disabled={true}
          />
          <TextField
            onBlur={handleNewReallocationUpdate}
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={recordToEdit.name}
          />
          <TextField
            onBlur={handleNewReallocationUpdate}
            autoFocus
            margin="dense"
            id="priority"
            label="Priority"
            type="number"
            fullWidth
            variant="standard"
            // defaultValue={recordToEdit.priority}
            defaultValue={reallocations && calcNewReallocationPriority()}
          />
          <TextField
            onBlur={handleNewReallocationUpdate}
            sx={{
              textarea: {
                fontFamily: "monospace",
              },
            }}
            multiline
            minRows={4}
            margin="dense"
            id="query"
            label="Query"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={recordToEdit.query}
          />
          <FormControl margin="dense" fullWidth variant="standard">
            <InputLabel id="attribute-to-update-label">
              Target Attribute
            </InputLabel>
            <Select
              labelId="attribute-to-update-label"
              id="attribute_to_update"
              name="attribute_to_update"
              label="Target Attribute"
              defaultValue="project"
              onBlur={handleNewReallocationUpdate}
            >
              <MenuItem value="project" id="attribute_to_update">
                project
              </MenuItem>
            </Select>
          </FormControl>
          {targetAttributeError && (
            <p style={{ color: "red" }}>{targetAttributeError}</p>
          )}
          <TextField
            onBlur={handleNewReallocationUpdate}
            autoFocus
            margin="dense"
            id="value_to_assign"
            label="Target Value"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={recordToEdit.value_to_assign}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNewReallocationDialogSave}>Save</Button>
          <Button onClick={handleNewReallocationDialogCancel}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Reallocations;
