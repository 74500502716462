import React from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import UMassChanLogo from "./UMassChan_Vertical_White.png";
import {
  Person,
  RequestPage,
  Biotech,
  CompareArrows,
  DarkMode,
  LightMode,
} from "@mui/icons-material";

const NavBar = ({ user, groups, darkMode, setDarkMode }) => {
  const location = useLocation().pathname;
  const userIsAdmin = groups.some(group => group.startsWith('AWSBillingAdministrators'));

  return (
    <AppBar
      style={{ backgroundColor: "#035CB8" }}
      position="static"
      sx={{ marginBottom: "1rem", padding: "0.5rem" }}
    >
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: 2,
        }}
      >
        {/* Logo & Title */}
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <img
            style={{ height: "50px", width: "auto" }}
            className="logo"
            alt="UMass Chan logo"
            src={UMassChanLogo}
          />
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            AWS Billing
          </Typography>
        </Box>

        {/* Navigation Buttons */}
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
          <Button
            color="inherit"
            component={Link}
            to="/"
            variant={location === "/" ? "outlined" : ""}
            sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
          >
            <RequestPage />
            Reports
          </Button>
          <Button
            color="inherit"
            component={Link}
            to="/projects"
            variant={location === "/projects" ? "outlined" : ""}
            sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
          >
            <Biotech />
            Projects
          </Button>
          {userIsAdmin && (
            <Button
              color="inherit"
              component={Link}
              to="/reallocations"
              variant={location === "/reallocations" ? "outlined" : ""}
              sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
            >
              <CompareArrows />
              Reallocations
            </Button>
          )}
        </Box>

        {/* User Info & Dark Mode Toggle */}
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Person />
          <Typography variant="body1">{user}</Typography>
          <Tooltip title="Toggle Dark Mode">
            <IconButton onClick={() => setDarkMode(!darkMode)} color="inherit">
              {darkMode ? <LightMode /> : <DarkMode />}
            </IconButton>
          </Tooltip>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
