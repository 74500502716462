import React, { useEffect, useState } from "react"
import { Amplify, API } from 'aws-amplify'
import AWSConfig from './aws-exports'
import { InputLabel, MenuItem, FormControl, Select, Button, Box, ToggleButtonGroup, ToggleButton } from '@mui/material';
import './App.css'

const Reports = () => {
  const [months, setMonths] = useState([]);
  const [startMonth, setStartMonth] = useState("");
  const [endMonth, setEndMonth] = useState("");
  const [reportType, setReportType] = useState("summary");

  const handleStartMonthSelect = (event) => {
    const selectedMonth = event.target.value
    setStartMonth(selectedMonth);
    if (reportType === "dbr") {
      setEndMonth(selectedMonth)
    }
  };

  const handleEndMonthSelect = (event) => {
    setEndMonth(event.target.value)
  }

  const handleReportTypeToggle = (event, newReportType) => {
    if (newReportType) {
      setReportType(newReportType)
      if (newReportType === 'dbr') {
        setEndMonth(startMonth)
      }
    }
  };

  const getAvailableReports = (event) => {
    API.get("reports", "reports", {})
      .then((response) => {
        setMonths(response.months);
        const now = new Date()
        const previousDate = new Date(now.getFullYear(), now.getMonth() - 1, 1)
        const year = previousDate.getFullYear();
        const month = (previousDate.getMonth() + 1).toString().padStart(2, "0");
        const formattedDate = `${year}-${month}`;
        setStartMonth(formattedDate);
        setEndMonth(formattedDate);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const handleDownloadReport = (event) => {
    // For dbr, we use startMonth as both start and end
    const effectiveEndMonth = reportType === "dbr" ? startMonth : endMonth
    console.log(`downloading ${reportType} report for ${reportType === 'dbr' ? `month ${startMonth}` : `range ${startMonth} to ${endMonth}`}`);
    API.get("reports", "reports", {
      queryStringParameters: {
        startMonth: startMonth,
        endMonth: effectiveEndMonth,
        type: reportType,
      },
    })
      .then((response) => {
        console.log(response);
        window.open(response.presigned_url, "noreferrer");
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  

  useEffect(() => {
    Amplify.configure(AWSConfig);
    getAvailableReports();
  }, []);

  return (
    <>
      <Box
        display="flex"
        flexWrap="wrap"
        flexGrow={1}
        alignItems="center"
        justifyContent="center"
        columnGap={2}
      >
        <FormControl sx={{ minWidth: 120, color: "black" }}>
          <InputLabel>{reportType === "dbr" ? "Month" : "Start Month"}</InputLabel>
          <Select
            id="start-month"
            value={startMonth}
            onChange={handleStartMonthSelect}
            label={reportType === "dbr" ? "Month" : "Start Month"}
          >
            {months.map((month, index) => (
              <MenuItem onChange={handleStartMonthSelect} key={index} value={month}>
                {month}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {reportType === "summary" && (
          <FormControl sx={{ minWidth: 120, color: "black" }}>
            <InputLabel>End Month</InputLabel>
            <Select
              id="end-month"
              value={endMonth}
              onChange={handleEndMonthSelect}
              label="End Month"
            >
              {months.map((month, index) => (
                <MenuItem onChange={handleEndMonthSelect} key={index} value={month}>
                  {month}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        
        <ToggleButtonGroup
          sx={{
            m: 2,
            minWidth: 120,
          }}
          color="primary"
          value={reportType}
          exclusive
          onChange={handleReportTypeToggle}
        >
          <ToggleButton
            sx={{
              color: "primary.main",
              backgroundColor: "primary",
              borderColor: "primary.main",
              "&.Mui-selected": {
                color: "white",
                backgroundColor: "primary.main",
                "&:hover": {
                  backgroundColor: "primary.dark",
                },
              },
            }}
            value="summary"
          >
            Summary
          </ToggleButton>
          <ToggleButton
            sx={{
              color: "primary.main",
              backgroundColor: "primary",
              borderColor: "primary.main",
              "&.Mui-selected": {
                color: "white",
                backgroundColor: "primary.main",
                "&:hover": {
                  backgroundColor: "primary.dark",
                },
              },
            }}
            value="dbr"
          >
            DBR
          </ToggleButton>
        </ToggleButtonGroup>
        <FormControl>
          <Button
            variant="contained"
            margin="dense"
            onClick={handleDownloadReport}
            disabled={months.indexOf(endMonth) > months.indexOf(startMonth)}
          >
            Download Report
          </Button>
        </FormControl>
        {}
      </Box>
    </>
    )
}

export default Reports
