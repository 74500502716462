import React, { useEffect, useState, useMemo } from "react";
import "./App.css";
import Nav from "./Nav.js";
import routes from "./routes.js";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Amplify, Auth, Hub } from "aws-amplify";
import AWSConfig from "./aws-exports";
import {
  createTheme,
  ThemeProvider,
  CssBaseline,
  Box,
  Card,
  CardContent,
  Typography,
  CircularProgress,
} from "@mui/material";

import useMediaQuery from "@mui/material/useMediaQuery";
import { jwtDecode } from 'jwt-decode'
import '@fontsource/open-sans';

Amplify.configure(AWSConfig);

const AccessDeniedPage = () => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      backgroundColor: "#f5f5f5",
    }}
  >
    <Card
      sx={{
        maxWidth: "450px",
        borderRadius: "15px",
        background: "rgba(255, 255, 255, 0.9)",
        boxShadow: 3,
        p: 3,
        textAlign: "center",
      }}
    >
      <CardContent>
        <Typography
          variant="h4"
          sx={{ fontWeight: "bold", color: "#333", mb: 2 }}
        >
          Access Denied
        </Typography>
        <Typography variant="body1" sx={{ color: "#555", mb: 3 }}>
          You do not have access to the AWS Billing Site. Please contact your
          administrator for access.
        </Typography>
      </CardContent>
    </Card>
  </Box>
);

const App = () => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const storedTheme = localStorage.getItem("darkMode");
  const [groups, setGroups] = useState([])
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem("user");
    return storedUser ? JSON.parse(storedUser) : null;
  });
  const [hasAccess, setHasAccess] = useState(true)
  const [loading, setLoading] = useState(!user);
  const [darkMode, setDarkMode] = useState(
    storedTheme ? JSON.parse(storedTheme) : prefersDarkMode
  );

const theme = useMemo(
    () =>
      createTheme({
        typography: {
          fontFamily: 'Open Sans, sans-serif',
        },
        palette: {
          mode: darkMode ? "dark" : "light",
          primary: { main: "#035CB8" },
          secondary: { main: "#FFC72C" },
          background: {
            default: darkMode ? "#121212" : "#ffffff",
            paper: darkMode ? "#1E1E1E" : "#f5f5f5",
          },
          text: {
            primary: darkMode ? "#ffffff" : "#000000",
            secondary: darkMode ? "#b0b0b0" : "#333333",
          },
        },
      }),
    [darkMode]
  );

  useEffect(() => {
    localStorage.setItem("darkMode", JSON.stringify(darkMode));

    // Apply the dark-mode class to the <body> tag
    if (darkMode) {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }
  }, [darkMode]);

  useEffect(() => {
    const checkSession = async () => {
      try {
        const session = await Auth.currentSession();
        const idToken = session.getIdToken().getJwtToken();
        const decodedToken = jwtDecode(idToken);
        const userEmail = decodedToken.email;
        setUser(userEmail)

        const groupStr = decodedToken["custom:groups"]
        const groupsArray = groupStr.replace(/^\[|\]$/g, "").split(",").map(group => group.trim());
        setGroups(groupsArray)

        const hasAccess = groups.some(group => 
          group.startsWith('AWSBillingAdministrators') || group.startsWith('AWSBillingUsers')
        );
        setHasAccess(hasAccess)
        
        localStorage.setItem("user", JSON.stringify(userEmail));
      } catch (error) {
        console.error("Session check failed:", error);
        setUser(null);
        localStorage.removeItem("user")
      } finally {
        setLoading(false)
      }
    }
    checkSession()
  }, [user, groups, hasAccess]);

  useEffect(() => {
    const listener = Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          Auth.currentSession()
            .then((session) => {
              const idToken = session.getIdToken().getJwtToken()
              const decodedToken = jwtDecode(idToken)
              const userEmail = decodedToken.email;
              setUser(userEmail);
              localStorage.setItem("user", JSON.stringify(userEmail));
              setLoading(false);
            })
            .catch((err) => console.error("Error getting session:", err));
          break;
        case "signOut":
          setUser(null);
          localStorage.removeItem("user");
          break;
        case "signIn_failure":
          console.error("Sign-in failed", data)
          setUser(null);
          break
        default:
          break;
      }
    });
    return () => listener(); // return listener function for cleanup
  }, []);

  const handleLogin = () => {
    Auth.federatedSignIn({ provider: "AzureAD"}).catch((err) => {
      console.error("Federated sign-in failed:", err)
    })
  };

  useEffect(() => {
    if (!user) {
      const timer = setTimeout(() => {
        handleLogin();
      }, 3000); // Adjust delay time as needed (3000ms = 3 seconds)
      return () => clearTimeout(timer); // Cleanup timeout if component unmounts
    }
  }, [user]);

  if (loading && !user) {
    return (
      <div className="container vh-100 d-flex justify-content-center align-items-center">
        <div
          className="spinner-border text-primary"
          role="status"
          style={{ width: "3rem", height: "3rem" }}
        ></div>
      </div>
    );
  }

  if (!loading && !hasAccess) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AccessDeniedPage />
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        {user ? (
          <Nav user={user} groups={groups} darkMode={darkMode} setDarkMode={setDarkMode} />
        ) : null}
        {user ? (
          <div>
            <Routes>
              {routes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  element={route.component}
                />
              ))}
            </Routes>
          </div>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              width: "100vw",
              backgroundColor: "#035CB8", // Full blue background
            }}
          >
            {/* Centered White Card */}
            <Card
              sx={{
                maxWidth: "450px",
                borderRadius: "15px",
                background: "rgba(255, 255, 255, 0.9)",
                boxShadow: 3,
                p: 3,
                textAlign: "center",
              }}
            >
              <CardContent>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "bold", color: "#333", mb: 2 }}
                >
                  Welcome to the UMass Chan AWS Billing Site.
                </Typography>
                <Typography variant="body1" sx={{ color: "#555", mb: 3 }}>
                  You will be redirected and logged in momentarily...
                </Typography>
                <CircularProgress sx={{ color: "#035CB8" }} size={50} />
              </CardContent>
            </Card>
          </Box>
        )}
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
